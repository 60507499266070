<template>
  <section class="seminar-detail-inner-section pt-4 pt-xl-5" v-if="detail">
    <div class="container lib-inner-container">
      <ol class="breadcrumb bg-transparent p-0">
        <li class="breadcrumb-item"><nuxt-link to="/chuyen-de">Chuyên đề</nuxt-link></li>
        <li class="breadcrumb-item"><span>{{detail.class_name}}</span></li>
        <li class="breadcrumb-item"><span>{{detail.category_name}}</span></li>
        <li class="breadcrumb-item" aria-current="page">{{detail.name}}</li>
      </ol>
      <div class="row">
        <div class="col-12 col-lg-8">
          <Preview :detail="detail" @joinLearn="joinLearn" @followed="handleFollow"/>
          <div class="tabs-controller">
            <ul class="nav nav-pills mb-2 mb-xl-3 list-tabs" role="tablist">
              <li class="nav-item pr-3 pr-lg-4" v-if="detail.description">
                <span class="nav-link py-0 pl-0 pr-2 pr-lg-3 pointer" :class="{'active font-weight-bold': tabActive === 1}" @click="setScrollPosition(1, 'tab-content-1')" aria-selected="true">Giới thiệu</span>
              </li>
              <li class="nav-item pr-3 pr-lg-4" v-if="detail.collections.length">
                <span class="nav-link py-0 pl-0 pr-2 pr-lg-3 pointer" :class="{'active font-weight-bold': tabActive === 2}" @click="setScrollPosition(2, 'seminar-conent-sp')" aria-selected="false">Nội dung</span>
              </li>
              <li class="nav-item pr-3 pr-lg-4" v-if="hasTeacher">
                <span class="nav-link py-0 pl-0 pr-2 pr-lg-3 pointer" :class="{'active font-weight-bold': tabActive === 3}" @click="setScrollPosition(3, 'seminar-teacher-sp')" aria-selected="false">Giáo viên</span>
              </li>
<!--              <li class="nav-item">-->
<!--                <span class="nav-link py-0 pl-0 pr-2 pr-lg-3 pointer" :class="{'active font-weight-bold': tabActive === 4}" @click="setScrollPosition(4, 'seminar-rating-sp')" aria-selected="false">Đánh giá</span>-->
<!--              </li>-->
            </ul>
            <div class="tab-content" v-if="detail.description">
              <div class="tab-pane fade show active" id="tab-content-1" role="tabpanel" aria-labelledby="tab-controller-1" >
                <div class="display-html" :style="{'--line': openMore ? 10000 : 13}" ref="description" v-html="detail.description"></div>
                <div v-if="hasMore" class="view-more mt-3"><span @click="openMore = !openMore" class="pointer">{{ openMore ? 'Thu gọn' : 'Xem thêm' }}</span></div>
              </div>
            </div>
          </div>
          <Collections class="mt-3 mt-xl-4" :collections="detail.collections" :class-name="detail.class_name" type="topic"/>
          <Teachers class="mt-3 mt-xl-4" :topic-id="detail.id" @loaded="setHasTeacher"/>
<!--          <Rating class="mt-3 mt-xl-4" :detail="detail" type="topic"/>-->
        </div>
        <div id="relative-container-right" class="col-12 col-lg-4 position-sticky mt-3 mt-lg-0">
          <div class="bg-white mb-4 rounded-sm py-3 px-xl-4 form-check-sub" style="height: 170px">
            <div>
              <div class="text pl-2 d-flex">
                <img src="~/assets/icons/library/list-style.svg" alt="" class="align-self-start pt-1" />
                <div class="ml-2">Đăng kí gói cước MLearn để xem toàn bộ chuyên đề, tài liệu, bộ đề</div>
              </div>
              <div class="mt-4">
                <button class="btn btn-primary text-white w-100" @click="handleCheckSub">Đăng ký</button>
              </div>
            </div>
          </div>
          <Related :topic-id="detail.id" class="w-100 mb-3"/>
          <Group v-if="1===2" :group="group" @joinGroup="joinGroup"/>
        </div>
      </div>
    </div>
    <ModalJoin />
  </section>
</template>

<script>
import Preview from '~/components/library-v2/topic/preview.vue'
import Collections from '~/components/library-v2/topic/collection.vue'
import Teachers from '~/components/library-v2/topic/teacher.vue'
// import Rating from '~/components/library-v2/topic/rating.vue'
import Related from '~/components/library-v2/topic/related.vue'
import Group from '~/components/library-v2/topic/group.vue'

import ModalJoin from '~/components/library-v2/modal/join.vue'

import Resource from '~/common/api/resource'
const libraryResource = new Resource('library/topic_v2/getBySlug')
const groupResource = new Resource('library/topic/group')
const joinGroupResource = new Resource('library/topic/join-group')
const joinLearnResource = new Resource('library/topic/join')
export default {
  components: {
    Preview,
    Collections,
    Teachers,
    // Rating,
    Related,
    Group,
    ModalJoin
  },
  layout: 'thu-vien',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const token = store.state.token
    const slug = params.slug
    const { data } = await libraryResource.list({ slug }, token)
      .catch((err) => {
        if (err.response.data.status === 404) {
          return redirect('/')
        }
        return error({ statusCode: err.response.data.status })
      })
    if (!data) {
      return redirect('/')
    }
    const detail = data
    let tabActive = 1
    if (!detail.description) {
      tabActive = 2
    }
    if (!detail.collections.length) {
      tabActive = 4
    }
    const metaSeo = {
      title: detail.meta_title || detail.name,
      image: detail.meta_image || detail.avatar,
      description: detail.meta_description || detail.name,
      keyword: detail.meta_keyword || ''
    }
    const structuredData = {}
    return {
      metaSeo,
      structuredData,
      detail,
      group: null,
      tabActive,
      hasTeacher: false,
      hasMore: false,
      openMore: false
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  watch: {
    openMore (newValue, oldValue) {
      if (!newValue) {
        this.setScrollPosition(1, 'tab-content-1')
      }
    }
  },
  created () {
    this.loadGroup()
  },
  mounted () {
    const line = 13
    const lineHeight = 17
    if (this.$refs.description) {
      this.hasMore = this.$refs.description.clientHeight >= (line * lineHeight)
    }
  },
  methods: {
    joinLearn () {
      joinLearnResource.store({
        topic_id: this.detail.id
      })
        .then(() => {
          this.detail.is_joined = 1
        })
        .catch((err) => {
          if (err.response.data.status === 503) {
            $('#join-not-sub').modal('show')
          }
        })
    },
    joinGroup () {
      const groupId = this.group?.id || null
      if (!groupId) { return }
      joinGroupResource.store({
        group_id: groupId
      })
        .then(() => {
          this.group.is_joined = 1
        })
        .catch((err) => {
          if (err.response.data.status === 503) {
            $('#join-not-sub').modal('show')
          }
        })
    },
    handleFollow (follow) {
      this.detail.is_followed = follow
    },
    async loadGroup () {
      const { data } = await groupResource.list({ topic_id: this.detail.id })
      this.group = Array.isArray(data) ? null : data
    },
    setScrollPosition (tab = 1, id = '') {
      this.tabActive = tab
      if (id) {
        const element = document.querySelector(`#${id}`)
        if (element) {
          const y = element.offsetTop
          window.scroll({
            top: y,
            behavior: 'smooth'
          })
        }
      }
    },
    setHasTeacher (state) {
      this.hasTeacher = state
    },
    handleCheckSub () {
      this.$router.push('/goi-cuoc')
    }
  }
}
</script>

<style lang="scss" scoped>
  .seminar-detail-inner-section {
    padding-bottom: 4.6rem;
    @media (max-width: 1366px) {
    }
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      padding-bottom: 1.5rem;
    }
    .breadcrumb {
      .breadcrumb-item {
        font-size: .85rem;
        line-height: 1.2;
        color: #777777;
      }
    }
    .tabs-controller {
      padding-top: 1.5rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        padding-top: 1.5rem;
      }
      .list-tabs {
        .nav-link {
          line-height: 1.5;
          color: #979797;
          background-color: transparent;
          font-size: 1.3rem;
          @media (max-width: 1366px) {
            font-size: 1.125rem;
          }
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            font-size: 1.1rem;
          }
          &.active {
            color: #333333;
          }
        }
      }
      .tab-content {
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        padding: 1.5rem 1rem;
        @media (max-width: 1366px) {
          padding: 1rem .75rem;
        }
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          padding: 1rem .75rem;
        }
        .view-more{
          font-size: 1rem;
          line-height: 1.2;
          color: #888888;
        }
      }
    }
    #relative-container-right {
      height: fit-content;
      top: 12px;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
    }
  }
</style>
<style lang="scss">
  .seminar-detail-inner-section{
    .form-check-sub{
      padding: 1rem;
      height: 170px !important;
      .text{
        line-height: 1.6;
      }
      button{
        border-radius: 26px;
        padding: 13px 0;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
</style>
