<template>
  <div class="home-container quiz-layout-preview position-relative" v-if="detail">
    <div class="quiz-breadcrumb text-truncate font-weight-normal">
      <nuxt-link to="/trac-nghiem">Thư viện</nuxt-link> / {{ detail.category_name }} / {{ detail.class_name }} / kết quả
    </div>
    <div class="quiz-success font-weight-bold text-center text-uppercase text-primary m-auto mw-100">
      <div>Chúc mừng bạn đã hoàn thành xong bài trắc nghiệm</div>
      <div>{{ detail.name }}</div>
    </div>
    <div class="quiz-line mt-4"></div>
    <div class="quiz-content">
      <div class="row no-gutters">
        <div class="col-12 font-weight-bold quiz-note-primary text-primary mb-2">
          Bài làm của bạn <span class="font-weight-normal">(Đáp án bạn chọn được tô màu xanh dương).</span>
        </div>
        <div class="col-sm-9">
          <div class="quiz-list-question pb-5 bg-white" ref="quiz-list-question">
            <Question v-for="(item,index) in detail.questions" :key="item.id" :item="item" :index="index" :exam-id="detail.id" type-question="view"/>
          </div>
        </div>
        <div class="col-sm-3 quiz-list-short" ref="quiz-list-short">
          <div ref="quiz-list-short-sticky">
            <div class="quiz-point">
              <div class="quiz-point-show">Điểm số: <span>{{detail.user_score}}/{{detail.score}}</span></div>
              <div class="quiz-point-line"></div>
              <div class="quiz-point-time-exam">Số câu đã làm: <span>{{detail.number_answered}}/{{detail.number_question}}</span></div>
              <div class="quiz-point-time-exam pt-2">Thời gian làm bài: <span>{{detail.completion_time}}/{{ detail.time }} phút</span></div>
              <div class="row no-gutters row-custom row-padding-10 pt-3">
                <div class="col-sm-6">
                  <div class="d-flex align-items-center">
                    <span class="quiz-point-status success"></span>Đúng:<span class="quiz-point-status-number">{{ detail.number_correct }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="d-flex align-items-center">
                    <span class="quiz-point-status fail"></span>Sai:<span class="quiz-point-status-number">{{detail.number_incorrect}}</span>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="d-flex align-items-center">
                    <span class="quiz-point-status none"></span>Chưa làm:<span class="quiz-point-status-number">{{ detail.number_not_answered }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div :class="quizSticky ? 'quiz-sticky' : 'quiz-sticky-static'" :style="{width: quizSticky ? $refs['quiz-list-short'].clientWidth+'px' : '100%'}">
              <div class="quiz-list-short-form">
                <div class="title">Câu hỏi</div>
                <div class="quiz-list-question-short scrollbar">
                  <div class="custom-row">
                    <div class="custom-col-2 quiz-question-answer" v-for="(question,index) in detail.questions" :key="question.id" @click="scrollToQuestion(index)" :class="question.is_answered ? (question.is_correct ? 'success' : 'fail') : 'none'">
                      <span class="position-relative float-left d-inline-block number-answer">
                        <span class="number-answer-character pointer" :class="{'big-answer': detail.questions.length > 99}">{{ index + 1 }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="quiz-action-restart">
                <button @click="reWork">
                  Làm lại
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="quiz-relation" v-if="relations.length">
      <div class="quiz-relation-title">Có thể bạn quan tâm</div>
      <div class="quiz-relation-line"></div>
      <div class="quiz-relation-slider">
        <VueSlickCarousel v-bind="carouselSettings">
          <QuizItem class="px-2" v-for="item in relations" :key="item.id" :item="item" :playlist="playlists" @createdPlaylist="loadPlayList()" @showNotify="showNotify" :action="false"/>
          <template #prevArrow>
            <div class="custom-arrow">
              <img src="/icon/library/arrow-left.svg" />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-arrow">
              <img src="/icon/library/arrow-right.svg" />
            </div>
          </template>
        </VueSlickCarousel>
      </div>
    </div>
    <div class="notify" :style="{opacity: flagNotify ? 1 : 0}">{{ textNotify }}</div>
  </div>
</template>

<script>
import cookieParser from 'cookieparser'
import VueSlickCarousel from 'vue-slick-carousel'
import Question from '~/components/library/quiz/question.vue'
import Resource from '~/common/api/resource'
import QuizItem from '~/components/library/quiz/item.vue'
import { checkAllow } from '~/common/api/check-register'
const examResultResource = new Resource('library/exam/result')
const playlistResource = new Resource('library/playlist')
const examRelationResource = new Resource('library/exam/related')
export default {
  components: {
    Question,
    VueSlickCarousel,
    QuizItem
  },
  middleware ({ store, redirect, from }) {
    if (!store.state.authenticated) {
      return redirect('/thu-vien')
    }
  },
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req?.headers && req?.headers?.cookie) {
      const requestCookies = cookieParser.parse(req?.headers?.cookie)
      token = requestCookies.access_token || ''
    }
    const tmp1 = await examResultResource.list({ exam_slug: params.slug }, token)
      .catch((err) => {
        if (err.response.data.status === 404) {
          return redirect('/')
        }
        return error({ statusCode: err.response.data.status })
      })
    if (!tmp1) {
      return redirect('/')
    }
    if (!tmp1.data) {
      return redirect('/')
    }
    const detail = tmp1.data
    return {
      detail,
      quizSticky: false,
      processing: false,
      carouselSettings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      relations: [],
      playlists: [],
      flagNotify: false,
      textNotify: ''
    }
  },
  beforeMount () {
    window.addEventListener('scroll', this.handerScroll)
  },
  mounted () {
    this.loadPlayList()
    this.loadRelation()
    this.handerScroll()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handerScroll)
  },
  methods: {
    async loadRelation () {
      const { data } = await examRelationResource.list({ exam_id: this.detail.id })
      this.relations = data
    },
    handerScroll () {
      if (this.$refs['quiz-list-question'].clientHeight < this.$refs['quiz-list-short-sticky'].clientHeight) {
        this.quizSticky = false
        return
      }
      const top = window.scrollY
      const clientHeight = document.body.clientHeight
      if (this.relations.length) {
        if (clientHeight - top < 1000) {
          this.quizSticky = false
          return
        }
      } else if (clientHeight - top < 600) {
        this.quizSticky = false
        return
      }

      if (top > 370) {
        this.quizSticky = true
      } else {
        this.quizSticky = false
      }
    },
    scrollToQuestion (index) {
      const elmnt = document.getElementById('question-number-' + index)
      elmnt.scrollIntoView({ block: 'center' })
    },
    async loadPlayList () {
      const { data } = await playlistResource.list({ type: 'exam' })
      this.playlists = data
    },
    showNotify (text) {
      this.textNotify = text
      this.flagNotify = true
      setTimeout(() => {
        this.flagNotify = false
        this.textNotify = ''
      }, 3500)
    },
    async reWork () {
      const { data } = await checkAllow({
        class_id: this.detail.class_id,
        type: 'EXAM',
        object_id: this.detail.id
      })
      if (data.quiz === 0) {
        this.$store.dispatch('showStopModal', {
          type: 'EXAM',
          classId: this.detail.class_id,
          dataFree: data.quiz_free
        })
      } else {
        this.$router.push('/trac-nghiem/lam-bai/' + this.$route.params.slug)
      }
    }
  }
}
</script>

<style lang="scss">
  .quiz-layout-preview{
    .quiz-breadcrumb{
      padding:1.125rem 0;
      font-size: .875rem;
      color: #364560;
    }
    .quiz-success{
      font-size: 1.125rem;
    }
    .quiz-line{
      border: 1px solid #C4C4C4;
    }
    .quiz-content{
      padding-top: 1rem;
      padding-bottom: 6.25rem;
      .quiz-note-primary{
        font-size: 1.25rem;
      }
      .quiz-list-question{
        padding-right:40px;
        padding-left: 40px;
        margin-right: 20px;
        .quiz-question-item{
          padding-top:1.5rem;
          padding-bottom:1.5rem;
          border-bottom:1px solid #C4C4C4;
          &:last-child{
            border-bottom:0;
            padding-bottom:0;
          }
        }
      }
      .quiz-list-short{
        .quiz-sticky{
          position: fixed;
          top: 70px;
          z-index: 99;
        }
        .quiz-sticky-static
        {
          position: static;
        }
        .quiz-point{
          background: #FFFFFF;
          border: 1px solid #C4C4C4;
          border-radius: 5px;
          padding:16px 20px;
          margin-bottom: 20px;
          .quiz-point-show{
            font-weight: bold;
            font-size: 20px;
            line-height: 167.5%;
            color: #F83939;
            span{
              font-size: 26px;
              padding-left:10px;
            }
          }
          .quiz-point-line{
            border: 1px solid #FF0000;
            margin:10px 0 16px;
          }
          .quiz-point-time-exam{
            font-weight: 300;
            font-size: 16px;
            line-height: 110%;
            color: #445975;
            span{
              font-size: 20px;
              font-weight: bold;
            }
          }
          .quiz-point-status{
            border-radius: 50%;
            margin-right: 10px;
            display: inline-block;
            width: 24px;
            height: 24px;
            font-weight: 300;
            font-size: 16px;
            line-height: 70.5%;
            &.success{
              background: #73BF44;
            }
            &.fail{
              background: #F83939;
            }
            &.none{
              background: #E3E3E3;
            }
          }
          .quiz-point-status-number{
            font-weight: bold;
            font-size: 18px;
            color: #445975;
            padding-left:5px;
          }
        }
        .quiz-list-short-form{
          background: #FFFFFF;
          border: 0.5px solid #C4C4C4;
          border-radius: 5px;
          .title{
            background: #E2E2E2;
            box-shadow: 0px 2px 4px #D8DDE6;
            border-radius: 5px 5px 0px 0px;
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            color:#004390;
            padding:13px 0;
          }
          .quiz-list-question-short{
            min-height: 20vh;
            max-height: 40vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding:14px 27px 32px 27px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .custom-row{
              display: flex;
              flex-wrap: wrap;
              margin-left: -7px;
              margin-right: -7px;
              > [class*="custom-col-"] {
                padding-right: 7px;
                padding-left: 7px;
                padding-top:10px;
              }
              .custom-col-2{
                flex: 0 0 20%;
                max-width: 20%;
              }
            }
            .quiz-question-answer{
              .number-answer{
                height:38px;
                width:38px;
                background: #E3E3E3;
                border-radius: 50%;
                cursor: pointer;
                .number-answer-character{
                  position: absolute;
                  top:50%;
                  left:50%;
                  transform: translate(-50%,-50%);
                  color: #212121;
                  font-size: 16px;
                  line-height: 19px;
                  &.big-answer{
                    font-size: 14px;
                    line-height: 16px;
                  }
                }
              }
              &.primary{
                .number-answer{
                  background:#004390;
                  .number-answer-character{
                    color: #FFFFFF;
                  }
                }
              }
              &.success{
                .number-answer{
                  background: #73BF44;
                  .number-answer-character{
                    color: #FFFFFF;
                  }
                }
              }
              &.fail{
                .number-answer{
                  background: #F83939;
                  .number-answer-character{
                    color: #FFFFFF;
                  }
                }
              }
            }
          }
        }
        .quiz-action-restart{
          text-align: center;
          padding-top:24px;
          button{
            background:#004390;
            border: 1px solid #004390;
            border-radius: 20px;
            padding:10px 38px;
            color: #fff;
          }
        }
      }
    }
    .quiz-relation{
      margin-bottom:5rem;
      .quiz-relation-title{
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #172948;
      }
      .quiz-relation-line{
        margin-top:10px;
        border: 1px solid #C4C4C4;
      }
      .quiz-relation-slider{
        padding-top:16px;
        .custom-arrow{
          width: 32px;
          height: 32px;
          z-index: 99;
          opacity: 1;
          &.slick-prev{
            left:-10px;
          }
          &.slick-next{
            right:-10px;
          }
          &.slick-disabled{
            opacity: 0.5;
          }
        }
        .quiz-item{
          margin: 10px 0;
        }
      }
    }
    .notify{
      position: fixed;
      top: 32px;
      right: 32px;
      max-width: 250px;
      word-break: break-word !important;
      word-wrap: break-word !important;
      padding: 15px 14px 14px 16px;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(54, 99, 225, 0.21);
      border-radius: 4px;
      font-size: 16px;
      line-height: 19px;
      color: #172948;
      z-index: 999;
      transition: opacity 1s;
    }
  }
</style>
