<template>
  <div class="container ml-tai-lieu-playlist min-vh-100 mb-5" v-if="detail">
    <div class="breadcrumd-custom"><nuxt-link to="/thu-vien-cua-toi?tab=2">Thư viện của tôi </nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">{{detail.name}}</span></div>
    <ViewMode :title="detail.name" @mode="changeMode" />
    <div class="line"></div>
    <div :class="{'bg-white px-3': !viewList}">
      <div class="row list-data">
        <DocumentItem :class="viewList ? 'col-sm-6 col-md-4 col-xl-3 pt-2' : 'col-12 py-2'" :view-list="viewList" v-for="(item) in listData" :key="item.id" :item="item" :playlist="playlist" @createdPlaylist="loadPlaylist()" @showNotify="showNotify"/>
      </div>
      <NoData v-if="!listData.length && !loading" />
    </div>
    <Pagination class="mt-3" :total="listData.length" :value="query.page" @change="loadData" :limit="query.limit" />
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
  </div>
</template>

<script>
import ViewMode from '~/components/mlearn/view-mode/index.vue'
import DocumentItem from '~/components/mlearn/document/item.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Pagination from '~/components/mlearn/pagination/index'
import Resource from '~/common/api/resource'
const playlistResource = new Resource('library/playlist/all')
const playlistDetailResource = new Resource('library/playlist/getBySlug')
export default {
  components: {
    DocumentItem,
    PlaylistNotify,
    Pagination,
    ViewMode
  },
  layout: 'mlearn',
  middleware: 'authenticated',
  asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const viewList = true
    return {
      viewList,
      detail: null,
      playlist: [],
      query: {
        limit: 12,
        page: 1,
        total: 0,
        slug: params.slug
      },
      listData: [],
      loading: false,
      flagNotify: false,
      textNotify: ''
    }
  },
  created () {
    this.loadData()
    this.loadPlaylist()
  },
  methods: {
    async loadData (page = 1) {
      this.query.page = page
      if (this.loading) { return }
      this.loading = true
      const data = await playlistDetailResource.list(this.query)
      this.detail = data.data.info
      this.listData = data.data.items
      this.query.total = data.total
      this.loading = false
    },
    redirectLink (link) {
      this.$router.push('/thu-vien-cua-toi/' + link)
    },
    changeMode (mode) {
      this.viewList = mode
    },
    async loadPlaylist () {
      const { data } = await playlistResource.list({ type: 'doc' })
      this.playlist = data
    },
    showNotify (text) {
      this.flagNotify = false
      this.textNotify = text
      this.flagNotify = true
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-tai-lieu-playlist{
    .line{
      border-bottom: 1px solid #EBEBEB;
      margin:10px 0;
    }
    .list-data{
      .col-12{
        border-bottom: 1px solid #EBEBEB;
        &:first-child{
          padding-top: 24px !important;
        }
        &:last-child{
          padding-bottom: 24px !important;
          border-bottom: 0;
        }
      }
    }
  }
</style>
