<template>
  <section class="lib-inner-section pt-4 pt-xl-5">
    <div class="container position-relative">
      <button id="advance-filter-btn" @click="toggleFilter" class="btn btn-advance-toggle-filter bg-transparent position-absolute p-0 color-darkgrey fw-500 btn-no-outline d-flex align-items-center" :class="{'collapsed': !toggle}" data-target="#advance-filter" aria-controls="advance-filter">
        <img v-if="!toggle" src="~/assets/icons/library/settings.svg" height="26px" class="setting-icon" title="Hiển thị bộ lọc" alt="" />
        <img v-else src="~/assets/icons/library/close-dark.svg" height="26px" class="close-icon" title="Ẩn bộ lọc" alt="" />
        <span class="d-none d-lg-block">Bộ lọc</span>
      </button>
      <div class="pt-2 pt-lg-3 d-flex">
        <MenuLeft :show="toggle" type="TOPIC" />
        <div class="list-data" :class="{'w-100': !toggle}" id="list-data">
          <FormSearch type="video" />
          <div class="data-block text-nowrap position-relative p-0 bg-white my-2 my-lg-3 my-xl-4">
            <div class="swiper mySwiper px-3 px-xl-4 py-2 py-lg-3 py-xl-4">
              <div class="data-block-title font-weight-bold color-darkgrey d-flex align-items-center justify-content-between mb-0">
                <div class="display-html">Chuyên đề {{ classLevel }}</div>
                <span class="ml-2 d-flex align-items-center action flex-shrink-0">
                  <img src="~/assets/icons/library/list-grey.svg" :class="{'active': !viewList}" class="mr-2 pointer" alt="" @click="viewList = false">
                  <img src="~/assets/icons/library/app.svg" :class="{'active': viewList}" alt="" class="pointer" @click="viewList = true">
                </span>
              </div>
              <div class="data-divider my-2"></div>
              <div v-if="loading" class="list-cards row mt-2 mt-lg-3 mt-xl-4">
                <SkeletonItem v-for="ind in 12" :key="ind" class="mb-2 mb-lg-3 mb-xl-4" :class="{'col-12 col-sm-3': !toggle && viewList, 'col-12 col-sm-4': toggle && viewList, 'col-12': !viewList}" :view-list="viewList" />
              </div>
              <div v-else-if="datas.length" class="list-cards row mt-2 mt-lg-3 mt-xl-4">
                <TopicItem v-for="(item, ind) in datas" :key="ind" class="mb-2 mb-lg-3 mb-xl-4" :class="{'col-12 col-sm-3': !toggle && viewList, 'col-12 col-sm-4': toggle && viewList, 'col-12': !viewList}" :item="item" :view-list="viewList" />
              </div>
              <NoData v-else type="video" text="Không có dữ liệu" />
              <Pagination :page="query.page" :limit="3" :per-page="query.limit" :total="query.total" align="right" @change="loadData" />
            </div>
          </div>
          <div class="data-block text-nowrap position-relative p-0 bg-white my-3 my-xl-4" v-if="user">
            <Viewed />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import cookieParser from 'cookieparser'
import Cookies from 'js-cookie'
import MenuLeft from '~/components/library-v2/MenuLeft.vue'
import FormSearch from '~/components/library-v2/FormSearch.vue'
import SkeletonItem from '~/components/skeleton/topic/item.vue'
import TopicItem from '~/components/library-v2/topic/item.vue'
import Viewed from '~/components/library-v2/Viewed.vue'
import NoData from '~/components/nodata/new.vue'
import Pagination from '~/components/library-v2/Pagination.vue'
import Resource from '~/common/api/resource'
const libraryResource = new Resource('library/topic_v2')
const metaResource = new Resource('config/seo')
export default {
  components: {
    NoData,
    MenuLeft,
    FormSearch,
    SkeletonItem,
    TopicItem,
    Viewed,
    Pagination
  },
  layout: 'thu-vien',
  async asyncData ({ isDev, route, store, env, req, redirect, error }) {
    let viewList = 1
    if (req && req?.headers && req?.headers?.cookie) {
      const requestCookies = cookieParser.parse(req?.headers?.cookie)
      viewList = parseInt(requestCookies.viewList || 1)
    } else {
      viewList = parseInt(Cookies.get('viewList') || 1)
    }
    viewList = viewList === 1
    const meta = await metaResource.list({ path: route.path }).catch(() => {})
    const metaSeo = meta?.data || null
    const structuredData = {}
    const query = {
      keyword: '',
      limit: 16,
      page: 1,
      category_id: route.query.category_id || '',
      book_id: route.query.book_id || '',
      total: 0
    }
    return {
      datas: [],
      query,
      viewList,
      loading: false,
      metaSeo,
      structuredData
    }
  },
  data () {
    return {
      mySwiper: null,
      suppend: true,
      toggle: true,
      classLevel: ''
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  computed: {
    ...mapState([
      'user',
      'token'
    ]),
    ...mapGetters([
      'classes'
    ])
  },
  watch: {
    $route () {
      this.loadData()
    },
    viewList () {
      if (this.viewList) {
        Cookies.set('viewList', 1)
      } else {
        Cookies.set('viewList', 0)
      }
    }
  },
  mounted () {
    const classId = Cookies.get('interestedClass') || this.user?.class_id
    const currClass = this.classes.find(item => item.id === classId)
    this.classLevel = currClass ? currClass.name.toLowerCase() : ''
    this.query.class_id = classId || ''
    this.loadData()
    const element = this.$refs.swiper
    this.mySwiper = this.getSwiper(element, 4, '.custom-nav-next', '.custom-nav-prev')
  },
  beforeDestroy () {
    if (Array.isArray(this.mySwiper)) {
      this.mySwiper.forEach(o => o?.destroy())
    } else {
      this.mySwiper?.destroy()
    }
  },
  methods: {
    getSwiper (element, slidesPerView, nextEl, prevEl) {
      if (!window.Swiper || !element) {
        return
      }
      return new window.Swiper(element, {
        spaceBetween: 24,
        keyboard: {
          enabled: true
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
        watchSlidesProgress: true,
        breakpointsBase: 'container',
        breakpoints: {
          400: {
            slidesPerView: 1
          },
          1000: {
            slidesPerView: (slidesPerView - 1)
          },
          1300: {
            slidesPerView
          }
        },
        navigation: {
          nextEl,
          prevEl
        },
        on: {
          beforeInit () {
            // console.log('beforeInit')
          },
          init () {
            // console.log('init')
          },
          afterInit () {
            // console.log('afterInit')
          }
        }
      })
    },
    toggleFilter () {
      this.toggle = !this.toggle
    },
    async loadData (page = 1) {
      if (this.loading) { return }
      this.loading = true
      this.query.page = page
      const query = { ...this.query, ...this.$route.query }
      query.category_id = query.category_id || null
      query.book_id = query.book_id || null
      const { data, total } = await libraryResource.list(query, this.token)
      this.datas = data
      this.query.total = total
      this.loading = false
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lib-inner-section {
  padding-bottom: 8rem;
  @media (max-width: 1366px) {
    padding-bottom: 4rem;
  }
  @media (max-width: 1200px) {}
  @media (max-width: 992px) {}
  @media (max-width: 768px) {}
  @media (max-width: 576px) {
    padding-bottom: 1.5rem;
  }
  .data-divider {
    height: 1px;
    background-color: #e6e6e6;
  }
  .btn-advance-toggle-filter {
    z-index: 1000;
    top: 1.5rem;
    left: 1rem;
    gap: 1rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      top: 1rem;
      left: .5rem;
      gap: .5rem;
    }
  }
  .list-data {
    .data-block {
      border-radius: 8px;
      &-title {
        line-height: 1.2;
        font-size: 1.5rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          font-size: 1.2rem;
        }
        .action{
          .active{
            filter: brightness(0);
          }
        }
      }
      .custom-navigator-btn {
        width: 2rem;
        height: 2rem;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 100%;
        z-index: 99;
        background-color: #fff;
        top: calc(50% + 1.3rem);
        &.swiper-button-disabled {
          background-color: #828282bd !important;
          img {
            filter: brightness(0) invert(1) opacity(0.7);
          }
        }
        &.btn-nav-prev {
          left: 6px;
          transform: translateY(-50%);
        }
        &.btn-nav-next {
          right: 6px;
          transform: translateY(-50%);
          img {
            transform: rotateZ(180deg);
          }
        }
      }
    }
  }
}
</style>
