<template>
  <div class="quiz-guide">
    <div class="guide-title">
      Hướng dẫn làm bài
    </div>
    <!-- <div class="guide-item">
      <div class="guide-item-left">
        <strong>3</strong>/20
      </div>
      <div class="guide-item-right">
        Câu hỏi đang xem trên tổng số câu hỏi trong đề
      </div>
    </div>
    <div class="guide-item">
      <div class="guide-item-left">
        <div class="right-answer">A</div>
      </div>
      <div class="guide-item-right">
        Chọn đáp án đúng
      </div>
    </div> -->
    <div class="guide-item a-center">
      <div class="guide-item-left has-img">
        <img src="~/assets/images/icons/guide-right.svg" alt="">
      </div>
      <div class="guide-item-right">
        Chuyển sang câu hỏi tiếp theo hoặc vuốt màn hình sang trái
      </div>
    </div>
    <div class="guide-item a-center">
      <div class="guide-item-left has-img">
        <img src="~/assets/images/icons/guide-left.svg" alt="">
      </div>
      <div class="guide-item-right">
        Trở về câu hỏi trước đó hoặc vuốt màn hình sang phải
      </div>
    </div>
    <div class="guide-item a-center">
      <div class="guide-item-left has-check">
        <div class="guide-check">
          <img src="~/assets/images/icons/check.svg" alt="">
        </div>
      </div>
      <div class="guide-item-right">
        Đánh dấu để xem lại câu hỏi trước khi nộp bài nếu chưa chắc chắn câu trả lời
      </div>
    </div>
    <div class="guide-note">
      <div class="guide-note-title">Lưu ý</div>
      <div class="guide-note-item">
        <div class="note-item-left">
          <img src="~/assets/images/icons/dot.svg" alt="">
        </div>
        <div class="note-item-right">Những câu hỏi chưa chọn đáp án sẽ được tính là câu trả lời sai</div>
      </div>
      <div class="guide-note-item">
        <div class="note-item-left">
          <img src="~/assets/images/icons/dot.svg" alt="">
        </div>
        <div class="note-item-right">Nếu thoát ra khi đang làm bài, kết quả sẽ không được tính</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'empty'
}
</script>
<style lang="scss" scoped>
  .quiz-guide{
    background: #F3F6FB;
    height: 100vh;
    padding: 24px 16px;
    .guide-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 122%;
      text-align: center;
      text-transform: uppercase;
      // color: #F4B556;
      margin-bottom: 20px;
    }
    .guide-item {
      font-size: 16px;
      line-height: 145.7%;
      color: #212121;
      display: flex;
      margin-bottom: 20px;
      .guide-item-left {
        min-width: 40px;
        .right-answer {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          background:#004390;
          color: #fff;
          line-height: 30px;
        }
        &.has-img {
          padding-left: 10px;
          img {
            width: auto;
            height: auto;
          }
        }
        .guide-check {
          width: 20px;
          height: 20px;
          align-items: center;
          text-align: center;
          background:#004390;
          line-height: 20px;
          border-radius: 5px;
        }
        &.has-check {
          padding-left: 5px;
        }
      }
      .guide-item-right {
        margin-left: 20px;
      }
    }
    .guide-note {
      padding-left: 5px;
      .guide-note-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 145.7%;
        text-decoration-line: underline;
        // color: #FC4848;
        margin-bottom: 20px;
      }
      .guide-note-item {
        font-size: 16px;
        line-height: 145.7%;
        color: #212121;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .note-item-left {
          min-width: 7px;
        }
        .note-item-right {
          margin-left: 16px;
        }
      }
    }
  }
  .a-center {
    align-items: center;
  }
</style>
