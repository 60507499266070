<template>
  <div class="quiz-layout-make-quiz">
    <div class="quiz-header">
      <div class="container">
        <div class="row">
          <div class="col-5 col-sm-4 col-lg-3">
            <img src="/icon/library/quiz/logo.svg" alt="logo" class="pointer" @click="checkBeforeQuit" />
          </div>
          <div class="col-7 col-sm-8 col-lg-9 quiz-name text-truncate">
            {{ detail.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="quiz-sub-header" :class="{'quiz-sticky': quizSticky}">
      <div class="container">
        <div class="d-flex align-items-center justify-content-end">
          <span class="quiz-time"><img src="/icon/library/time.svg"/>
            {{$helper.convertTimeLeft(timeLeft)}}
          </span>
          <!-- <button class="quiz-pause" v-if="started && !showNotifyPauseStart" @click.prevent="checkBeforePause()">Tạm dừng</button> -->
          <!-- <button class="quiz-continue" v-else @click.prevent="handerContinute()">Tiếp tục</button> -->
          <button class="quiz-submit" @click.prevent="checkBeforeSubmit()">Nộp bài</button>
        </div>
      </div>
    </div>
    <div class="container quiz-content">
      <div class="row no-gutters">
        <div class="col-sm-9 quiz-list-question">
          <Question v-for="(item,index) in detail.questions" :key="item.id" :item="item" :index="index" :exam-id="detail.id" :started="started" type-question="take" @reasonQuestion="reasonQuestion"/>
        </div>
        <div class="col-sm-3 pl-3 quiz-list-short" ref="quiz-list-short">
          <div :class="{'quiz-sticky': quizSticky}" :style="{width: quizSticky ? $refs['quiz-list-short'].clientWidth+'px' : ''}">
            <div class="quiz-list-short-form">
              <div class="title">Câu hỏi</div>
              <div class="quiz-list-question-short scrollbar">
                <div class="custom-row">
                  <div class="custom-col-2 quiz-question-answer" v-for="(item,index) in detail.questions" :key="item.id" @click="scrollToQuestion(index)" :class="{'primary' : item.is_answered}">
                    <span class="position-relative float-left d-inline-block number-answer">
                      <span class="number-answer-character pointer" :class="{'big-answer': detail.questions.length > 99}">{{ index + 1 }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="quiz-status">Hoàn thành: <span>{{answered}}/{{detail.number_question}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="notify-pause-start" :style="{opacity: showNotifyPauseStart ? 1 : 0}">
      Chọn ‘’Tiếp tục” để tiếp tục làm bài
    </div>
    <b-modal id="modal-confirm-quiz" centered :modal-class="action" body-class="p-0" v-model="dialogVisiable" hide-header hide-footer :no-close-on-backdrop="action !== 'ended'" :no-close-on-esc="action !== 'ended'">
      <div class="content-modal" :class="action">
        <div class="content-confirm text-break" :class="{'text-center': (action !== 'pause' && action !== 'continute')}" v-html="notifyText"></div>
        <div class="action-modal text-center">
          <button v-if="action === 'submit'" class="action-cancel" @click.prevent="dialogVisiable = false">Hủy</button>
          <button v-if="action === 'ended'" class="action-submit" @click.prevent="handerSubmit()">Nộp bài</button>
          <button v-if="action === 'submit'" class="action-submit" @click.prevent="handerSubmit()">Nộp bài</button>
          <button v-if="action === 'pause'" class="action-cancel" @click.prevent="handerPause()">Thoát</button>
          <button v-if="action === 'pause'" class="action-submit" @click.prevent="dialogVisiable = false; startTime()">Tiếp tục làm bài</button>
          <button v-if="action === 'continute' || action === 'quit'" class="action-cancel" @click.prevent="handerQuit()">Thoát</button>
          <button v-if="action === 'continute'" class="action-submit" @click.prevent="handerContinute()">Tiếp tục làm bài</button>
          <button v-if="action === 'quit'" class="action-submit" @click.prevent="dialogVisiable = false">Tiếp tục làm bài</button>
        </div>
      </div>
    </b-modal>
    <ModalReason v-if="objectId" :object-id="objectId" @reasonSuccess="reasonSuccess"/>
  </div>
</template>

<script>
import Question from '~/components/library/quiz/question.vue'
import ModalReason from '~/components/library/modal/reason.vue'
import Resource from '~/common/api/resource'
const examStartResource = new Resource('library/exam/start')
const examPauseResource = new Resource('library/exam/pause')
const examContinueResource = new Resource('library/exam/continue')
const examSubmitResource = new Resource('library/exam/submit')
export default {
  components: {
    Question,
    ModalReason
  },
  layout: 'quiz',
  middleware ({ store, redirect, from }) {
    if (!store.state.authenticated) {
      return redirect('/thu-vien')
    }
  },
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const token = store.state.token
    const tmp1 = await examStartResource.list({ exam_slug: params.slug }, token)
      .catch((err) => {
        if (err.response.data.status === 404) {
          return redirect('/')
        }
        return error({ statusCode: err.response.data.status })
      })
    if (!tmp1) {
      return redirect('/')
    }
    if (!tmp1.data) {
      return redirect('/')
    }
    const detail = tmp1.data
    const action = detail.state === 2 ? 'continute' : 'pause'
    return {
      detail,
      timeLeft: (detail.time * 60 - detail.time_do),
      quizSticky: false,
      processing: false,
      myInterval: null,
      myTimeOut: null,
      started: true,
      dialogVisiable: false,
      notifyText: '',
      action,
      objectId: '',
      showNotifyPauseStart: false
    }
  },
  computed: {
    answered () {
      const answers = this.detail.questions.filter(value => value.is_answered !== 0)
      return answers.length
    }
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.handerQuit)
    window.addEventListener('scroll', this.handerScroll)
  },
  mounted () {
    if (this.action === 'continute') {
      this.dialogVisiable = true
      const questions = this.detail.questions
      const Answer = []
      questions.forEach((item, index) => {
        if (item.is_answered) {
          Answer.push(item)
        }
      })
      const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
      this.notifyText = `Trắc nghiệm <b>${this.detail.name}</b>.<br>Thời gian còn lại: <b>${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Số câu đã làm: ${Answer.length}/${questions.length}`
    }
    this.handerScroll()
    if (this.detail.state === 2) {
      this.showNotifyPauseStart = true
      this.started = false
      setTimeout(() => {
        this.showNotifyPauseStart = false
      }, 3500)
    } else {
      this.started = true
      this.startTime()
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.handerQuit)
    window.removeEventListener('scroll', this.handerScroll)
  },
  methods: {
    handerScroll () {
      const top = window.scrollY
      if (top > 80) {
        this.quizSticky = true
      } else {
        this.quizSticky = false
      }
    },
    scrollToQuestion (index) {
      const elmnt = document.getElementById('question-number-' + index)
      elmnt.scrollIntoView({ block: 'center' })
    },
    startTime () {
      if (this.timeLeft <= 0) { return }
      this.myInterval = setInterval(() => {
        this.timeLeft--
        if (this.timeLeft <= 0) {
          clearInterval(this.myInterval)
          this.action = 'ended'
          this.dialogVisiable = true
          this.notifyText = 'Đã hết thời gian làm bài'
          this.myTimeOut = setTimeout(() => {
            this.handerSubmit()
          }, 10000)
        }
      }, 1000)
    },
    handerPause () {
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }
      this.processing = true
      examPauseResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            clearInterval(this.myInterval)
            this.myInterval = null
            this.started = false
            this.action = ''
            this.dialogVisiable = false
            this.$router.push('/trac-nghiem')
            localStorage.setItem('quiz', this.detail.name)
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    handerContinute () {
      if (this.detail.state !== 2) {
        return
      }
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }

      this.processing = true
      examContinueResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            if (this.myInterval) { clearInterval(this.myInterval) }
            this.startTime()
            this.started = true
            this.dialogVisiable = false
            this.detail.state = 1
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    checkBeforePause () {
      this.action = 'pause'
      this.dialogVisiable = true
      const questions = this.detail.questions
      const Answer = []
      questions.forEach((item, index) => {
        if (item.is_answered) {
          Answer.push(item)
        }
      })
      if (this.myInterval) { clearInterval(this.myInterval) }
      const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
      this.notifyText = `Trắc nghiệm <b>${this.detail.name}</b>.<br>Thời gian còn lại: <b>${arrTime[1] !== '00' ? arrTime[1] + ' giờ ' : ''}${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Số câu đã làm: ${Answer.length}/${questions.length}`
    },
    checkBeforeQuit () {
      this.action = 'quit'
      this.dialogVisiable = true
      this.notifyText = 'Bạn chưa hoàn thành bài trắc nghiệm.<br>Chắc chắn muốn thoát ?'
    },
    checkBeforeSubmit () {
      this.action = 'submit'
      this.dialogVisiable = true
      const questions = this.detail.questions
      const noAnswer = []
      const noAnswerIndex = []
      questions.forEach((item, index) => {
        if (!item.is_answered) {
          noAnswer.push(item)
          noAnswerIndex.push(index + 1)
        }
      })
      if (noAnswer.length) {
        if (noAnswer.length >= 10) {
          this.notifyText = `Bạn vẫn còn ${noAnswer.length} câu chưa hoàn thành.<br>Chắc chắn muốn nộp bài ?`
        } else {
          this.notifyText = `Bạn vẫn còn câu ${noAnswerIndex.join(',')} chưa hoàn thành.<br>Chắc chắn muốn nộp bài ?`
        }
        return
      }
      if (this.timeLeft > 0) {
        const arrTime = this.$helper.convertTimeToDayArr(this.timeLeft)
        this.notifyText = `Thời gian còn lại: <b>${arrTime[1] !== '00' ? arrTime[1] + ' giờ ' : ''}${arrTime[2]} phút ${arrTime[3]} giây</b>.<br>Chắc chắn muốn nộp bài ?`
      }
    },
    handerSubmit () {
      if (this.processing) { return }
      const data = {
        exam_id: this.detail.id
      }
      this.processing = true
      examSubmitResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            if (this.myInterval) { clearInterval(this.myInterval) }
            if (this.myTimeOut) { clearTimeout(this.myTimeOut) }
            // this.startTime()
            // this.started = true
            this.$router.push('/trac-nghiem/ket-qua/' + this.$route.params.slug)
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    handerQuit (e) {
      this.$router.push('/trac-nghiem')
      // e.preventDefault()
      // e = e || window.event
      // if (e) {
      //   e.returnValue = 'Bạn có chắc chắn muốn rời trang này không ?'
      // }
      // return 'Bạn có chắc chắn muốn rời trang này không ?'
    },
    reasonQuestion (questionId) {
      this.objectId = questionId
    },
    reasonSuccess () {
      this.objectId = ''
    }
  }
}
</script>

<style lang="scss">
  .quiz-layout-make-quiz{
    position: relative;
    .quiz-header{
      background:#004390;
      height:80px;
      color: #fff;
      padding:25px 0;
      .quiz-name{
        font-weight: bold;
        font-size: 18px;
        line-height: 167.5%;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }
    .quiz-sub-header{
      padding:9px 0;
      border: 0.25px solid #dee2e6;
      box-shadow: 0px 2px 4px #D8DDE6;
      background: #FFFFFF;
      .quiz-time{
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #FC4848;
        margin-right:32px;
        img{
          width: 20px;
          margin-right:10px;
        }
      }
      .quiz-pause{
        background: #FFFFFF;
        border: 0.5px solid #004390;
        font-size: 16px;
        line-height: 122%;
        color:#004390;
        padding:12px 34px;
        margin-right:32px;
        width:145px;
        border-radius: 22.5px;
      }
      .quiz-continue{
        background: #FFFFFF;
        border: 0.5px solid var(--warning);
        font-size: 16px;
        line-height: 122%;
        color:var(--warning);
        padding:12px 34px;
        margin-right:32px;
        width:145px;
        border-radius: 22.5px;
      }
      .quiz-submit{
        border:0;
        background:#004390;
        border-radius: 22.5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 122%;
        color: #FFFFFF;
        padding:12px 34px;
      }
      &.quiz-sticky{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
      }
    }
    .quiz-content{
      padding-top: 16px;
      padding-bottom: 150px;
      .quiz-list-question{
        background: #FFFFFF;
        padding: 20px !important;
        @media (max-width: 767px) {
          padding-right: 0;
        }
        .quiz-question-item{
          padding-top:24px;
          padding-bottom:24px;
          border-bottom:0.5px solid #76829C;
          &:first-child{
            padding-top:0;
          }
          &:last-child{
            border-bottom:0;
            padding-bottom:0;
          }
        }
      }
      .quiz-list-short{
        .quiz-sticky{
          position: fixed;
          top: 80px;
          @media (max-width: 767px) {
            display: none;
          }
        }
        .quiz-list-short-form{
          background: #FFFFFF;
          border: 0.5px solid #C4c4c4;
          box-shadow: 0px 2px 4px #D8DDE6;
          border-radius: 5px;
          .title{
            background: #E2E2E2;
            box-shadow: 0px 2px 4px #D8DDE6;
            border-radius: 5px 5px 0px 0px;
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            color:#004390;
            padding:13px 0;
          }
          .quiz-list-question-short{
            //min-height: 40vh;
            max-height: 60vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding:14px 27px 32px 27px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .custom-row{
              display: flex;
              flex-wrap: wrap;
              margin-left: -7px;
              margin-right: -7px;
              > [class*="custom-col-"] {
                padding-right: 7px;
                padding-left: 7px;
                padding-top:10px;
              }
              .custom-col-2{
                flex: 0 0 20%;
                max-width: 20%;
              }
            }
            .quiz-question-answer{
              .number-answer{
                height:2.375rem;
                width:2.375rem;
                background: #E3E3E3;
                border-radius: 50%;
                cursor: pointer;
                .number-answer-character{
                  position: absolute;
                  top:50%;
                  left:50%;
                  transform: translate(-50%,-50%);
                  color: #212121;
                  font-size: 1rem;
                  &.big-answer{
                    font-size: .875rem;
                  }
                }
              }
              &.primary{
                .number-answer{
                  background:#004390;
                  .number-answer-character{
                    color: #FFFFFF;
                  }
                }
              }
              &.success{
                .number-answer{
                  background: #73BF44;
                  .number-answer-character{
                    color: #FFFFFF;
                  }
                }
              }
            }
          }
        }
        .quiz-status{
          padding-top:.75rem;
          font-size: .875rem;
          color: #1B1B1B;
          span{
            font-weight: bold;
          }
        }
      }
    }
    .notify-pause-start{
      padding:1rem 2rem;
      background: #FFFFFF;
      border: 1px solid #FFEBCC;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
      border-radius: 4px;
      font-size: 1rem;
      text-align: center;
      color:var(--warning);
      width:20rem;
      max-width: 100%;
      position: fixed;
      transform: translateX(-50%);
      left: 50%;
      top: 10rem;
      z-index: 1000;
      transition: opacity 1.5s;
    }
  }
  #modal-confirm-quiz{
    &___BV_modal_outer_{
      z-index: 9200 !important;
    }
    &.pause, &.continute{
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
    }
    .modal-dialog{
      min-width:27.25rem;
      @media (max-width: 576px) {
        min-width:20rem;
      }
      .content-modal{
        padding: .75rem .75rem 2rem;
        @media (max-width: 1920px) {}
        @media (max-width: 992px) {}
        @media (max-width: 576px) {
          padding: .75rem;
        }
        &.end {
          padding: 2.5rem 2rem 2rem;
          @media (max-width: 1920px) {}
          @media (max-width: 992px) {}
          @media (max-width: 576px) {
            padding: 1.5rem;
          }
        }
        .content-confirm{
          font-weight: normal;
          font-size: 1.125rem;
          color: #212121;
          padding:0 2rem;
          @media (max-width: 1920px) {}
          @media (max-width: 992px) {}
          @media (max-width: 576px) {
            padding:0 1rem;
          }
        }
        .action-modal{
          padding-top:1.25rem;
          @media (max-width: 1920px) {}
          @media (max-width: 992px) {}
          @media (max-width: 576px) {
            padding:1rem 1rem 0;
          }
          .action-cancel{
            background: #FFFFFF;
            border: 0.5px solid #004390;
            font-size: 1rem;
            color:#004390;
            margin-right:2rem;
            border-radius: 22.5px;
            padding:.75rem 2rem;
            min-width: 9.5rem;
            @media (max-width: 1920px) {}
            @media (max-width: 992px) {}
            @media (max-width: 576px) {
              padding: .5rem 1rem;
              min-width: 8rem;
            }
          }
          .action-submit{
            border:0;
            background:#004390;
            font-weight: bold;
            font-size: 1rem;
            color: #FFFFFF;
            border-radius: 22.5px;
            padding:.75rem 2rem;
            min-width: 9.5rem;
            @media (max-width: 1920px) {}
            @media (max-width: 992px) {}
            @media (max-width: 576px) {
              padding: .5rem 1rem;
              min-width: 8rem;
            }
          }
        }
      }
    }
  }
</style>
